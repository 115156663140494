import { useEffect } from "react";
import "./App.css";
import { DataUpdateProvider } from "./contexts/DataUpdateContext";
import Main from "./views/Main";
function App() {
  useEffect(() => {
    const handleOrientationChange = () => {
      const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);
      console.log(navigator.userAgent);
      if (isMobileDevice) {
        if (window.matchMedia("(orientation: landscape)").matches) {
          alert("This website is not made for horizontal view, please view it in portrait");
        }
      }
    };

    window.addEventListener("resize", handleOrientationChange);
    // Initial check
    handleOrientationChange();

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);
  return (
    <div className='App'>
      <DataUpdateProvider>
        <Main />
      </DataUpdateProvider>
    </div>
  );
}
export default App;
